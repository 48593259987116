/* eslint-disable new-cap */
import { FieldInputProps, FormikProps } from 'formik'
import React, { useState, useEffect } from 'react'


interface UploadProps {
  field: FieldInputProps<any>
  form: FormikProps<any>
  id: string
  value: any
  disabled?: boolean
  modelname: string
  readonly?: boolean
  type?: string
  placeholder?: string
}

const UploadInput = (props: UploadProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { field, form, value, placeholder, ...rest } = props

  const [ file, setFile ] = useState(field.value)

  const removeFile = () => {
    setFile(null)
  }

  const chooseFile = e => {
    try {
      const el = e.target
      const file = el.files[0]
      if (file) {
        setFile(file)
      }
    } catch (er) {
      console.error(er)
    }
  }

  useEffect(() => {
    if (file) {
      form.setFieldValue(field.name, file)
    } else if (field.value) {
      form.setFieldValue(field.name, null)
    }
  }, [ file ])

  return file ? (
    <div className="inlineupload">
      <div><a href={file.caption ? file.file : URL.createObjectURL(file)} target="_blank" rel="noopener noreferrer">{file.name || file.caption.split(':').pop()}</a></div>
      <div>
        <button type="button" title="Remove file" className="upload-button" onClick={removeFile}>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ stroke: 'var(--svg-icon)' }}>
            <path d="M3.33325 4.66667H12.6666M11.9999 4.66667V12C11.9999 12.7367 11.4033 13.3333 10.6666 13.3333H5.33325C4.59659 13.3333 3.99992 12.7367 3.99992 12V4.66667M9.99992 2.5H5.99992M6.66659 7.33333V10.6667M9.33325 7.33333V10.6667" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
      </div>
    </div>
  ) : (
    <div className="inlineupload">
      <button type="button" className="upload-button" style={{ position: 'relative' }}>
        <input
          id={`${field.name}-upload`}
          type="file"
          onChange={chooseFile}
          value={undefined}
          {...rest}
        />
        {placeholder || 'Upload a file'}
      </button>
    </div>
  )
}

export default UploadInput
