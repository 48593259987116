/* eslint-disable no-process-env */
import { connect, Form, useFormikContext, getIn, FormikErrors, FormikTouched, FormikProps } from 'formik'
import React, { useState, useEffect, ReactElement, useRef } from 'react'
import isEqual from 'react-fast-compare'

import { useCustomCompareMemo, changeValue } from './utils'

/*
 * Custom wrapper for Formik Form which allows us to set the form context
 * inside the formik render method.
*/

interface ErrorInterface {
  onError: (errors: FormikErrors<unknown>, touched: FormikTouched<unknown>) => any;
}

export const ErrorListener = ({ onError }: ErrorInterface) => {
  const formik = useFormikContext()
  const [ formikChecks, setFormikChecks ] = useState({
    errors: formik.errors,
    touched: formik.touched,
    submitCount: formik.submitCount
  })
  useEffect(() => {
    if (
      (!formik.isValidating &&
        !formik.isSubmitting &&
        Object.keys(formik.errors).length &&
        formik.submitCount > formikChecks.submitCount
      )
      || (formik.submitCount && !isEqual(formik.touched, formikChecks.touched))
      || (formik.submitCount && !isEqual(formik.errors, formikChecks.errors))
    ) {
      if (onError) {
        onError(formik.errors, formik.touched)
      }
      const touched = changeValue({}, formik.errors)
      if (!isEqual(formik.touched, touched)) {
        formik.setTouched(touched)
      }
      setFormikChecks({
        errors: formik.errors,
        touched: formik.touched,
        submitCount: formik.submitCount
      })
    }
  }, [
    useCustomCompareMemo(formik.errors),
    formik.isSubmitting,
    formik.isValidating,
    formik.submitCount,
    onError,
    formikChecks.submitCount
  ])

  return null
}

interface CustomFormProps {
  formik?: FormikProps<any>;
  config: any;
  component?: any;
  id?: string;
  className?: string;
  children?: React.ReactNode;
}

const CustomForm = ({ formik, config, component, id, className, children }: CustomFormProps): ReactElement => {
  let { onChange } = config
  const { autosave, onError } = config
  const [ oldFormik, setOldFormik ] = useState(formik)
  const timer = useRef(null)

  useEffect(() => {
    setOldFormik(formik)
    if (onChange) {
      onChange = onChange.bind(config)
      const diff = Object.keys(formik.values)
        .filter(k => !isEqual(getIn(formik.values, k), getIn(oldFormik.values, k)))
      onChange(diff, formik)
    }
    if (autosave && autosave.key) {
      clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        sessionStorage.setItem(autosave.key, JSON.stringify(formik.values))
      }, autosave.delay || 1000)
    }
  }, [ useCustomCompareMemo(formik.values) ])

  useEffect(() => {
    if (autosave && autosave.key) {
      const initialValues = sessionStorage.getItem(autosave.key)
      if (initialValues) {
        formik.setValues(JSON.parse(initialValues))
      }
    }
  }, [])

  const Component = component || Form
  return (
    <Component
      id={id}
      className={className}
      onSubmit={(e: any) => {
        e.stopPropagation()
        e.cancelBubble = true
        formik.handleSubmit(e)
      }}
    >
      {children}
      <ErrorListener onError={onError} />
    </Component>
  )
}

export default connect(CustomForm)
