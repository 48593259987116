import classNames from 'classnames'
import { ErrorMessage, Field } from 'formik'
import React from 'react'

import { CustomErrorMessage } from '../renderField'
import SearchFormSelect from '../selects/SearchFormSelect'
import { isConditional } from '../utils'


export default function SelectInput({ config, field, props }) {
  if (field.options) {
    const options = field.options.filter(o => isConditional(o, 'show', true, props))
    return (
      <div className={classNames('feffield', field.name, field.classes, { 'input-group-prefix': !!field.prefix, 'input-group-suffix': !!field.suffix })} key={`${config.name}-${field.name}`}>
        {field.label ? <label htmlFor={`${config.selector.replace('#', '')}__${field.name}`} dangerouslySetInnerHTML={{ __html: field.label }} /> : null}
        <div className="input-group">
          {field.prefix && <div className="input-group-addon" dangerouslySetInnerHTML={{ __html: field.prefix }} />}
          <Field component={SearchFormSelect} {...field} id={`${config.selector.replace('#', '')}__${field.name}`} className="input-field" options={options} />
          {field.suffix && <div className="input-group-addon" dangerouslySetInnerHTML={{ __html: field.suffix }} />}
        </div>
        <ErrorMessage name={field.name}>{msg => <CustomErrorMessage msg={msg} name={field.name} id={`${config.selector.replace('#', '')}__${field.name}`} />}</ErrorMessage>
      </div>
    )
  } return null
}
