import { ErrorMessage } from 'formik'
import React, { useEffect } from 'react'
// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from 'react-google-recaptcha'

import { CustomErrorMessage } from '../renderField'


let grecaptchaObject = window.grecaptcha

const setCaptchaObject = () => {
  grecaptchaObject = window.grecaptcha
}

export default function RecaptchaInput({ config, field, props, recaptchaRef }) {

  return window.CAPTCHA_KEY && field.version !== 'v3' ? (<div className={field.classes ? `${field.classes} feffield recaptcha` : 'feffield recaptcha'} key={`${config.name}-${field.name}`}>
    <ReCAPTCHA
      theme={field.theme ? field.theme : 'light'}
      sitekey={window.CAPTCHA_KEY}
      size={field.version}
      ref={recaptchaRef}
      grecaptcha={grecaptchaObject}
      asyncScriptOnLoad={setCaptchaObject}
      onChange={e => {
        if (field.name) {
          props.setFieldValue(field.name, e)
        }
      }}
    />
    <ErrorMessage name={field.name}>{msg => <CustomErrorMessage msg={msg} name={field.name} id={`${config.selector.replace('#', '')}__${field.name}`} />}</ErrorMessage>
  </div>) : null
}
