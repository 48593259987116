import { getIn, useFormikContext } from 'formik'
import React, { useRef, lazy, Suspense } from 'react'
import { CSSTransition } from 'react-transition-group'


const AutoComplete = lazy(() => import('./inputs/AutoComplete'))
const CheckboxInput = lazy(() => import('./inputs/CheckboxInput'))
const CurrencyInput = lazy(() => import('./inputs/CurrencyInput'))
const DomstringInput = lazy(() => import('./inputs/DomstringInput'))
const DynamicLocationsInput = lazy(() => import('./inputs/DynamicLocationsInput'))
const EmailInput = lazy(() => import('./inputs/EmailInput'))
const HiddenInput = lazy(() => import('./inputs/HiddenInput'))
const LocationsInput = lazy(() => import('./inputs/LocationsInput'))
const MapInput = lazy(() => import('./inputs/MapInput'))
const NumberInput = lazy(() => import('./inputs/NumberInput'))
const RecaptchaInput = lazy(() => import('./inputs/RecaptchaInput'))
const SelectInput = lazy(() => import('./inputs/SelectInput'))
const SignatureInput = lazy(() => import('./inputs/SignatureInput'))
const TextAreaInput = lazy(() => import('./inputs/TextAreaInput'))
const TextInput = lazy(() => import('./inputs/TextInput'))
const UploadInput = lazy(() => import('./inputs/UploadInput'))


export const CustomErrorMessage = ({ msg, name, id }) => {
  const nodeRef = useRef(null)
  const formik = useFormikContext()
  const timer = useRef(null)
  if (typeof msg === 'object' && msg.non_field_errors) {
    msg = 'This field is invalid'
  }
  return (
    <CSSTransition
      nodeRef={nodeRef}
      appear={true}
      in={true}
      timeout={200}
      className="feffield-error"
      classNames="feffield-error"
      mountOnEnter
      unmountOnExit
      onEntered={() => {
        clearTimeout(timer.current)
        if (!window.currentElement) {
          window.currentElement = document.getElementById(id)
          if (window.currentElement) {
            window.currentElement.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }
        }
        timer.current = setTimeout(() => {
          if (getIn(formik.touched, name) && getIn(formik.errors, name)) {
            formik.setErrors({})
            formik.setTouched({})
            window.currentElement = null
          }
        }, 3000)
      }}
    >
      <div ref={nodeRef}>
        {msg}
      </div>
    </CSSTransition>
  )
}

export default function renderField({ config, field, props, recaptchaRef, key }) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { value, yup, ...rest } = field
  switch (field.input) {
    case 'textarea':
      return (
        <Suspense fallback={null}>
          <TextAreaInput key={key} config={config} field={rest} />
        </Suspense>
      )
    case 'select':
      return (
        <Suspense fallback={null}>
          <SelectInput key={key} config={config} field={rest} props={props} />
        </Suspense>
      )
    case 'locations':
      return (
        <Suspense fallback={null}>
          <LocationsInput key={key} config={config} field={rest} />
        </Suspense>
      )
    case 'checkbox':
      return (
        <Suspense fallback={null}>
          <CheckboxInput key={key} config={config} field={rest} />
        </Suspense>
      )
    case 'hidden':
      return (
        <Suspense fallback={null}>
          <HiddenInput key={key} config={config} field={rest} />
        </Suspense>
      )
    case 'currency':
      return (
        <Suspense fallback={null}>
          <CurrencyInput key={key} config={config} field={rest} />
        </Suspense>
      )
    case 'domstring':
      return (
        <Suspense fallback={null}>
          <DomstringInput key={key} config={config} field={rest} />
        </Suspense>
      )
    case 'number':
      return (
        <Suspense fallback={null}>
          <NumberInput key={key} config={config} field={rest} />
        </Suspense>
      )
    case 'email':
      return (
        <Suspense fallback={null}>
          <EmailInput key={key} config={config} field={rest} />
        </Suspense>
      )
    case 'upload':
      return (
        <Suspense fallback={null}>
          <UploadInput key={key} config={config} field={rest} />
        </Suspense>
      )
    case 'recaptcha':
      return (
        <Suspense fallback={null}>
          <RecaptchaInput key={key} config={config} field={rest} recaptchaRef={recaptchaRef} props={props} />
        </Suspense>
      )
    case 'signature':
      return (
        <Suspense fallback={null}>
          <SignatureInput key={key} config={config} field={rest} />
        </Suspense>
      )
    case 'autocomplete':
      return (
        <Suspense fallback={null}>
          <AutoComplete key={key} config={config} field={rest} props={props} />
        </Suspense>
      )
    case 'map':
      return (
        <Suspense fallback={null}>
          <MapInput key={key} config={config} field={rest} props={props} />
        </Suspense>
      )
    case 'dynamic-locations':
      return (
        <Suspense fallback={null}>
          <DynamicLocationsInput key={key} config={config} field={rest} props={props} />
        </Suspense>
      )
    default: // Text field
      return (
        <Suspense fallback={null}>
          <TextInput key={key} config={config} field={rest} />
        </Suspense>
      )
  }
}
