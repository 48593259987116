import React from 'react'
import { createRoot } from 'react-dom/client'

import FrontendForm from './FrontendForm'
import { FEFormConfig } from './interfaces'


declare global {
  interface Window {
      forms:FEFormConfig[],
      request: any
      CAPTCHA_KEY: any
      log_ga_event: any
      currentElement: any
      initializeForms: any
      grecaptcha?: any
      loadingCaptcha?: boolean
  }
}

const forms = window.forms
let recaptcha = false
let recaptchav3 = false

if (typeof forms !== 'undefined') {
  try {
    forms.map((form: FEFormConfig) => {
      const selectors = Array.from(document.querySelectorAll(form.selector))

      form.fields.forEach(f => {
        if (f.name === 'recaptcha') {
          recaptcha = true
          if (f.version === 'v3') { recaptchav3 = true }
        }
      })

      return selectors.map(selector => {
        form.root = createRoot(selector)
        return form.root.render(
          <React.StrictMode>
            <FrontendForm config={form} />
          </React.StrictMode>
        )
      })
    })

    if (window.CAPTCHA_KEY && !window.grecaptcha && recaptcha) {
      const script = document.createElement('script')
      script.src = 'https://www.google.com/recaptcha/api.js'
      if (recaptchav3) { script.src += `?render=${window.CAPTCHA_KEY}` }
      document.body.appendChild(script)
    }
  } catch (e) { console.error(e) }

  window.initializeForms = () => {
    forms.map((form: FEFormConfig) => {
      const selectors = Array.from(document.querySelectorAll(form.selector))

      return selectors.map(selector => {
        if (!form.root) {
          form.root = createRoot(selector)
        }
        return form.root.render(
          <React.StrictMode>
            <FrontendForm config={form} />
          </React.StrictMode>
        )
      })
    })
  }
}
