import classNames from 'classnames'
import { Formik, FormikProps, setIn, getIn, yupToFormErrors, validateYupSchema, FormikHelpers, FormikValues } from 'formik'
import React, { useState, useRef, useEffect, createRef, useCallback } from 'react'
import { ReCAPTCHA } from 'react-google-recaptcha'
import Modal from 'react-modal'
import { CSSTransition } from 'react-transition-group'
import { ErrorMessageHandler, YupBuilder, types } from 'schema-to-yup'
import slugify from 'slugify'
import * as yup from 'yup'

import CustomForm from './CustomForm'
import { FEFormFieldConfig, FEFormConfig, TabProps } from './interfaces'
import renderField from './renderField'
import { isConditional } from './utils'


const CheckmarkSVG = () => (
  <button>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="m5 8 2 2 4-4" stroke="#CBD5E1" strokeWidth="1.5" />
    </svg>
  </button>
)

const CheckmarkSVGFilled = () => (
  <button className='filled'>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="m5 8 2 2 4-4" stroke="#CBD5E1" strokeWidth="1.5" />
    </svg>
  </button>
)

interface StepperProps {
  steps: FEFormFieldConfig[]
  currentStep: number
  isMobile: boolean
}

const Stepper = ({ steps, currentStep, isMobile }: StepperProps) => (
  <div className={classNames('fefstepper', { mobile: isMobile })}>
    {steps.map((step, index) => (
      <div key={index} className={classNames('step', { active: index === currentStep, complete: index < currentStep })}>
        <div className="step-icon">{index < currentStep ? <CheckmarkSVGFilled /> : <CheckmarkSVG />}</div>
        <div className="step-title" dangerouslySetInnerHTML={{ __html: step.label ? step.label : step.name }} />
      </div>
    ))}
  </div>
)

interface FrontendFormProps {
  config: FEFormConfig
}

interface ErrorMessageProps {
  [key: string]: any
}

interface YupProperties {
  [key: string]: any
}

Modal.setAppElement(document.body)

class CustomErrorMessageHandler extends ErrorMessageHandler {
  typeHandler: any

  constraints: any

  errMessages: any

  key: any

  value: any

  parentNode: any

  keyPath: any

  type: any

  description: any

  title: any

  config: any

  constructor(typeHandler: any, config = {}) {
    super(typeHandler, config)
    this.config = config
    this.typeHandler = typeHandler
    this.init()
  }

  init() {
    const { typeHandler } = this
    if (!typeHandler) { return }
    this.constraints = typeHandler.constraints
    this.errMessages = typeHandler.errMessages
    this.key = typeHandler.key
    this.value = typeHandler.value // raw type constraints
    this.constraints = typeHandler.constraints // type constraints (possibly filtered)
    this.parentNode = typeHandler.parentNode
    this.keyPath = typeHandler.keyPath
    this.type = typeHandler.type
    this.description = typeHandler.description
    this.title = typeHandler.title
    this.setErrMessage()
  }

  errMessageMap(value = {}) {
    const key = this.errMessagesMapKey
    return value[key] || this.errMessages
  }

  get errMessageKey() {
    return this.config.errMessageKey || 'errMessage'
  }

  get errMessagesMapKey() {
    return this.config.errMessagesMapKey || 'errMessages'
  }

  setErrMessage() {
    const { typeHandler } = this
    const { value } = typeHandler
    if (!value.errMessage) { return }
    const valueKey = this.errMessageKey
    const errMessageMap = this.errMessageMap(value)
    errMessageMap[this.key] = value[valueKey] || errMessageMap[this.key]
  }

  validationErrorMessage(msgName: any) {
    const {
      constraints,
      key,
      description,
      title,
      typeHandler,
      keyPath,
      parentNode
    } = this
    const errMsg = this.errMessageFor(msgName)
    return typeof errMsg === 'function'
      ? errMsg(constraints, {
        key,
        title,
        description,
        typeHandler,
        parentNode,
        keyPath
      })
      : errMsg
  }

  errMessageFor(msgName: string | number) {
    const { errMessages, key, keyPath } = this
    let keyPathReverse = keyPath.split('.')
    keyPathReverse.pop()
    keyPathReverse.reverse()
    keyPathReverse.push('')
    keyPathReverse = keyPathReverse.join('.')
    const errMsg = getIn(errMessages, keyPathReverse ? `${keyPathReverse}${key}` : key)
    return errMsg ? errMsg[msgName] : errMessages[`$${msgName}`]
  }
}

const fileHandler = () => yup.mixed().test({
  message: 'Please provide a supported file type',
  test: (file, context) => {
    const isValid = file?.name || file?.file
    if (!isValid) {context?.createError()}
    return isValid
  }
})

class MyCustomYupBuilder extends YupBuilder {
  [x: string]: any

  get validator() {
    return yup
  }

  setLocale() {
    if (this.config?.locale) {
      yup.setLocale(this.config.locale)
    }
  }

  getAdditionalProperties(schema: { additionalProperties: any }) {
    return schema.additionalProperties
  }

  getRequired(obj: { required: any }) {
    const { getRequired } = this.config
    return getRequired ? getRequired(obj) : obj.required || []
  }

  getProps(obj: any) {
    return this.config.getProps(obj)
  }

  getType(obj: any) {
    return this.config.getType(obj)
  }

  getName(obj: any) {
    return this.config.getName(obj)
  }

  get yupSchema() {
    return yup.object().shape(this.shapeConfig)
  }

  buildProperties() {
    const propKeys = Object.keys(this.properties)
    const buildProp = (this.config.buildProp || this.buildProp).bind(this)
    return propKeys.reduce(buildProp, {})
  }

  getRequiredPropsList() {
    return Array.isArray(this.required) ? [ ...this.required ] : []
  }

  buildProp(propObj: any, key: string | number) {
    const value = this.properties[key]
    const required = this.getRequiredPropsList()
    const setRequired = (this.config.setRequired || this.setRequired).bind(
      this
    )
    // normalize required for prop
    setRequired(value, key, required)
    // set schema property entry
    const setPropEntry = (this.config.setPropEntry || this.setPropEntry).bind(
      this
    )
    setPropEntry(propObj, key, value)
    return propObj
  }

  // normalize required for prop
  setRequired(value: { required: any }, key: any, required: string | any[]) {
    const isRequired = required.indexOf(key) >= 0
    value.required = this.isRequired(value) || isRequired
    return value
  }

  setPropEntry(propObj: { [x: string]: any }, key: string | number, value: { required: any }) {
    propObj[key] = {
      required: value.required,
      ...value
    }
  }

  isRequired(value: any) {
    return this.config.isRequired(value)
  }

  propsToShape(opts = { name: '' }) {
    const shape = this.objPropsToShape(opts)
    this.objPropsShape = shape
    this.addPropsShape = this.additionalPropsToShape(opts, shape)
    return shape
  }

  additionalPropsToShape(opts: { name: string }, shape: any) {
    return shape
  }

  objPropsToShape({ name }) {
    const properties = {
      ...this.properties
    }
    const keys = Object.keys(properties)
    return keys.reduce((acc, key) => {
      const value = properties[key]
      const argsObj = {
        name,
        key,
        value
      }
      const yupSchemaEntry = this.propToYupSchemaEntry(argsObj)
      if (yupSchemaEntry) {
        acc[key] = yupSchemaEntry
      }
      return acc
    }, {})
  }

  propToYupSchemaEntry({ name, key, value = {} }) {
    const schemaEntryObj = {
      schema: this.schema,
      parentNode: this.parentNode || this,
      name,
      key,
      value,
      config: this.config,
      builder: this
    }
    return this.createYupSchemaEntry(schemaEntryObj)
  }

  createYupSchemaEntry(opts = {}) {
    return this.config.createYupSchemaEntry(opts)
  }
}

function buildYup(schema: {
  type: string;
  log: boolean;
  required: any[];
  properties: { [x: string]: any }
}, config = {}) {
  return new MyCustomYupBuilder(schema, { ...config }).yupSchema
}

function jsonShape(steps: any[]) {
  const properties: YupProperties = {}
  const yup_shape = {
    type: 'object',
    log: true,
    required: [],
    properties: { ...properties }
  }
  const shape = { ...yup_shape }
  const errMessages: ErrorMessageProps = {}
  const config = {
    errMessages,
    createErrorMessageHandler: (typeHandler: any, cfg: any) => new CustomErrorMessageHandler(typeHandler, cfg),
    typesHandlers: {
      file: fileHandler
    },
    types: {
      string: types.toYupString,
      number: types.toYupNumberSchemaEntry,
      boolean: types.toYupBoolean,
      array: types.toYupArray,
      object: types.toYupObject,
      file: fileHandler
    }
  }
  steps.forEach(step => {
    step.fields.forEach((field: { name: any; yup: any }) => {
      const nullable = false
      const { name, yup: fieldYup } = field
      if (fieldYup && fieldYup.type) {
        if (name.includes('.')) {
          let last: { properties: any; type?: string; log?: boolean; required?: any[] }
          config.errMessages = setIn(config.errMessages, name, {})
          const parts = name.split('.')
          parts.forEach((part: string, idx: number) => {
            if (idx + 1 === parts.length) {
              last.properties[part] = { ...properties, type: fieldYup.type, format: fieldYup.format }
              fieldYup.checks.forEach((check: { key: string | number; val: any; err: any }) => {
                last.properties[part][check.key] = check.val
                if (check.err) {
                  if (typeof check.val === 'object') {
                    const key = Object.keys(check.val).find(k => check.val[k].then.required)
                    if (key) {
                      config.errMessages = setIn(config.errMessages, `${name}.required`, check.err)
                    }
                  } else {
                    config.errMessages = setIn(config.errMessages, `${name}.${check.key}`, check.err)
                  }
                }
              })
              last.properties[part].nullable = nullable || false
            } else if (idx) {
              if (getIn(last.properties, part)) {
                last = last.properties[part]
              } else {
                last = last.properties[part] = { ...yup_shape, properties: { ...properties } }
              }
            } else if (getIn(shape.properties, part)) {
              last = shape.properties[part]
            } else {
              last = shape.properties[part] = { ...yup_shape, properties: { ...properties } }
            }
          })
        } else {
          config.errMessages[name] = {}
          shape.properties[name] = { type: fieldYup.type, format: fieldYup.format }
          fieldYup.checks.forEach((check: { key: string | number; val: any; err: any }) => {
            shape.properties[name][check.key] = check.val
            if (check.err) { config.errMessages[name][check.key] = check.err }
          })
          shape.properties[name].nullable = nullable || false
        }
      }
    })
  })
  return buildYup(shape, config)
}

const FieldSet = ({ id, group, config, props, recaptchaRef }) => {
  const clusters = []
  let inner = []
  const group_length = group.length - 1
  group.forEach((field: FEFormFieldConfig, idx: number) => {
    if (field.edit) {
      if (!isConditional(field, 'edit', false, props)) {
        return
      }
    }
    if (!field.name) {
      return
    }
    if (field.cols) {
      if (field.input === 'break') {
        inner.push(null)
      } else {
        inner.push(renderField({ config, field, props, recaptchaRef, key: `${id}-${field.name}` }))
      }
      if ((field.cols && inner.length >= field.cols) || (field.cols && idx === group_length)) {
        clusters.push(<div key={`cluser-${clusters.length}`} className="ffcluster">{inner}</div>)
        inner = []
      }
    } else {
      clusters.push(renderField({ config, field, props, recaptchaRef, key: `${id}-${field.name}` }))
    }
  })

  return clusters.length ? (
    <fieldset id={id}>
      {clusters}
    </fieldset>
  ) : null
}

const Step = ({
  step,
  index,
  isMultiStep,
  currentStep,
  config,
  recaptchaRef,
  props
}) => {
  if (step.edit && Array.isArray(step.edit) && !isConditional(step, 'edit', false, props)) {
    return null
  }

  const groups: { [key: string]: FEFormFieldConfig[] } = {}
  // Group fields based on the 'group' property
  step.fields.forEach((field: FEFormFieldConfig) => {
    const groupName = field.group || 'default'
    if (!groups[groupName]) {
      groups[groupName] = []
    }
    groups[groupName].push(field)
  })
  const [ open, setOpen ] = useState(isConditional(step, 'open', false, props))
  const tab_props: TabProps = { checked: undefined }
  if (step.collapsable) {
    tab_props.checked = open
  }
  const nodeRef = useRef(null)
  if (isMultiStep && index !== currentStep) {
    return null
  }
  return (
    <section id={step.name ? step.name : `step-${index}`} className={classNames(slugify(step.name || '', { lower: true }))}>
      {step.collapsable ? (
        <>
          <input {...tab_props} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
              setOpen(true)
            } else {
              setOpen(false)
            }
          }} className="tab__input" type="checkbox" id={`cb-${step.name ? step.name : `step-${index}`}`} />
          <label htmlFor={`cb-${step.name ? step.name : `step-${index}`}`} className={classNames('tab__label', { open })}>{step.label} <div className="tab__indicator"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.667 9.3335L8.00033 6.66683L5.33366 9.3335" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /></svg></div></label>
          <CSSTransition
            nodeRef={nodeRef}
            appear={true}
            in={open}
            timeout={200}
          >
            <div className="tab__content" ref={nodeRef}>
              <div className="tab__inner">
                {Object.keys(groups).map(group => (
                  <FieldSet
                    key={slugify(group, { lower: true })}
                    id={slugify(group, { lower: true })}
                    group={groups[group]}
                    config={config}
                    recaptchaRef={recaptchaRef}
                    props={props}
                  />
                ))}
              </div>
            </div>
          </CSSTransition>
        </>
      ) : (
        Object.keys(groups).map(group => (
          <FieldSet
            key={slugify(group, { lower: true })}
            id={slugify(group, { lower: true })}
            group={groups[group]}
            config={config}
            recaptchaRef={recaptchaRef}
            props={props}
          />
        ))
      )}
    </section>
  )
}

function flatten(data: any): any {
  const result = {}
  function recurse(cur: any, prop: string) {
    if ((cur instanceof Date) || (cur instanceof File) || (cur instanceof Blob)) {
      result[prop] = cur
    } else if (Object(cur) !== cur) {
      result[prop] = cur
    } else if (Array.isArray(cur)) {
      for (var i = 0, l = cur.length; i < l; i++) { // eslint-disable-line
        recurse(cur[i], `${prop}[${i}]`)
      }
      if (l == 0) { // eslint-disable-line
        result[prop] = []
      }
    } else {
      let isEmpty = true
      if ((cur instanceof Date) || (cur instanceof File) || (cur instanceof Blob)) {
        isEmpty = false
        result[prop] = cur
      } else {
        for (const p in cur) { // eslint-disable-line
          isEmpty = false
          recurse(cur[p], prop ? `${prop}.${p}` : p)
        }
        if (isEmpty && prop) {
          result[prop] = {}
        }
      }
    }
  }
  recurse(data, '')
  return result
}

function buildFormData(formData: FormData, data: { [x: string]: any }, parentKey = null) {
  if (data && typeof data === 'object' && Array.isArray(data)) {
    if (Object.keys(data).length) {
      Object.keys(data).forEach(key => {
        buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
      })
    }
  } else if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob)) {
    if (Object.keys(data).length) {
      Object.keys(data).forEach(key => {
        buildFormData(formData, data[key], parentKey ? `${parentKey}.${key}` : key)
      })
    }
  } else {
    const value = data === null ? '' : data

    if (value instanceof Blob && value) {
      formData.append(parentKey, value, `${parentKey}.${value.type.split('/').pop()}`)
    } else if (value instanceof File) {
      formData.append(parentKey, value)
    } else if (value && typeof value === 'object' && !Object.keys(value).length) {
      return
    } else {
      formData.append(parentKey, value as string)
    }
  }
}


function jsonToFormData(data: any) {
  const formData = new FormData()

  buildFormData(formData, data)

  return formData
}


const FrontendForm = ({ config }: FrontendFormProps) => {
  const [ currentStep, setCurrentStep ] = useState(0)
  const [ isMobile, setIsMobile ] = useState(window.innerWidth < 768)
  const [ recaptchaVersion, setRecaptchaVersion ] = useState('normal')

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768) // Adjust the threshold as needed
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const isMultiStep = config.multistep

  let steps = config.fields.filter(field => field.input === 'step' || field.input === 'group')
  let non_step_fields = []
  if (!steps.length) {
    steps = [ { fields: config.fields } ]
  } else {
    non_step_fields = config.fields.filter(field => field.input !== 'step' && field.input !== 'group')
  }

  const handleNextStep = (props: FormikProps<any>, fields: FEFormFieldConfig[]) => {
    let touched = {}
    fields.forEach(field => {
      touched = setIn(touched, field.name, true)
    })
    props.setTouched(touched)
    props.validateForm().then(value => {
      const invalid_fields = Object.keys(value)
      if (invalid_fields.length === 0) {
        setCurrentStep(prevStep => Math.min(prevStep + 1, steps.length - 1))
      }
      const step_valid = fields.every(field => !invalid_fields.includes(field.name))
      if (step_valid) {
        setCurrentStep(prevStep => Math.min(prevStep + 1, steps.length - 1))
      }
    })
  }

  const handlePrevStep = () => {
    setCurrentStep(prevStep => Math.max(prevStep - 1, 0))
  }

  let initvals: any = { non_field_errors: '' }
  non_step_fields.forEach(field => {
    if (field.name) {
      if (![ null, undefined, '' ].includes(field.value)) {
        initvals = setIn(initvals, field.name, field.value)
      }
    }
  })
  steps.forEach(({ fields }) => {
    fields.forEach(field => {
      if (field.edit) {
        // @ts-expect-error
        if (!isConditional(field, 'edit', false, { initialValues: initvals, values: initvals })) {
          return
        }
      }
      if (field.name) {
        if (field.input === 'checkbox') {
          if (![ null, undefined, '' ].includes(field.value)) {
            initvals = setIn(initvals, field.name, field.value)
          }
        } else if (![ null, undefined, '' ].includes(field.value)) {
          initvals = setIn(initvals, field.name, field.value)
        }
      }
      if (field.input === 'recaptcha' && field?.version === 'v3' && recaptchaVersion !== 'v3') {
        setRecaptchaVersion('v3')
      }
    })
  })

  const recaptchaRef = createRef<ReCAPTCHA>()

  const find_keep_fields = (values: any, fields: FEFormFieldConfig[], keep_fields: any, parent: string) => {
    for (const field of fields) {
      if (field.fields) {
        keep_fields = find_keep_fields(values, field.fields, keep_fields, field.name)
      }
      if (field.input === 'hidden') {
        keep_fields = setIn(keep_fields, parent ? `${parent}.${field.name}` : field.name, getIn(values, parent ? `${parent}.${field.name}` : field.name))
      } else if (getIn(values, parent ? `${parent}.${field.name}` : field.name)) {
        keep_fields = setIn(keep_fields, parent ? `${parent}.${field.name}` : field.name, '')
      }
    }
    return keep_fields
  }

  // @ts-ignore
  window.find_keep_fields = find_keep_fields

  const handleSubmit = useCallback((
    values: FormikValues,
    formikBag: FormikHelpers<FormikValues>
  ) => {
    if (config.onSubmit) {
      return config.onSubmit(values, formikBag, config, recaptchaRef)
    }
    let multipart = false
    let props = {
      headers: { 'Content-Type': 'application/json' }
    }
    Object.keys(flatten(values)).forEach(k => {
      const val = getIn(values, k)
      if (val instanceof Blob || val instanceof File) {
        multipart = true
        // @ts-ignore
        props = {}
      }
    })
    let clean = multipart ? jsonToFormData(values) : JSON.stringify(values)

    if (config.preSubmit) {
      clean = multipart ? jsonToFormData(config.preSubmit(values)) : JSON.stringify(config.preSubmit(values))
    }

    delete values.non_field_errors
    const visible_fields = config.fields.filter(f => f.type !== 'hidden').map(f => f.name)
    const keep_fields = find_keep_fields(values, config.fields, {}, null)

    return fetch(config.action, {
      method: 'POST',
      body: clean,
      ...props
    }).then(response => {
      if (recaptchaRef.current) {
        recaptchaRef.current.reset()
      }
      if (response.status !== 200) {
        throw response
      } else {
        window.log_ga_event(null, 'lead', values.source)
        formikBag.resetForm({ values: { ...keep_fields } })
        formikBag.setStatus(config.messages.success)
        if (isMultiStep) {
          setCurrentStep(0)
        }
        if (config.onSuccess) {
          config.onSuccess(values, formikBag, config, recaptchaRef, response)
          return response
        }
      }
      return response
    }).catch(async response => {
      if (response.status === 400) {
        return flatten(await response.json())
      }
      return await response
    }).then(respo => {
      let errors: any = {}
      const non_field_errors: any = {}
      Object.keys(respo).forEach(k => {
        if (visible_fields.includes(k)) {
          errors = setIn(errors, k, respo[k])
        } else {
          non_field_errors[k] = respo[k]
        }
      })
      const touched: any = {}
      Object.keys(errors).forEach(k => (touched[k] = true))
      formikBag.setErrors(errors)
      formikBag.setTouched(touched)
      if (Object.keys(non_field_errors).length > 0) {
        console.error({ non_field_errors })
        formikBag.setStatus(config.messages.error)
      }
    }).finally(() => {
      formikBag.setSubmitting(false)
    })
  }, [ recaptchaRef ])

  let validation = jsonShape([ ...steps, { fields: non_step_fields } ])
  if (config.createValidation) {
    validation = config.createValidation(jsonShape([ ...steps, { fields: non_step_fields } ]), yup)
  }
  // @ts-ignore
  if (config.onValidate) {
    validation = validation.test(
      config.onValidate.key,
      (value, context) => config.onValidate.callback(value, context, yup)
    )
  }
  return (
    <div className="fefform">
      {isMultiStep ? (
        <Stepper steps={steps} currentStep={currentStep} isMobile={isMobile} />
      ) : null}
      {config.title &&
        <div className='form-top'
          dangerouslySetInnerHTML={{ __html: config.title }}
        />
      }
      <Formik
        initialValues={initvals}
        validate={async value => {
          try {
            await validateYupSchema(value, validation, false, value)
          } catch (err) {
            return yupToFormErrors(err) // for rendering validation errors
          }

          return {}
        }}
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values, formikBag) => {
          if (recaptchaVersion === 'v3') {
            window.grecaptcha?.execute(window.CAPTCHA_KEY, { action: `submit_${slugify(config.selector, { replacement: '_', strict: true, lower: true })}` }).then((token: string) => {
              values.recaptcha = token
              handleSubmit(values, formikBag)
            }).catch((e: any) => {
              console.error(e)
            })
          } else {
            handleSubmit(values, formikBag)
          }
        }}
      >
        {(props: FormikProps<any>) => {
          let disabled = false
          if (props.isSubmitting) {
            disabled = true
          }
          return (
            <CustomForm
              config={config}
            >
              <div className="tab__content">
                {non_step_fields.length ? (
                  <FieldSet id={'no-group'} group={non_step_fields} config={config} recaptchaRef={recaptchaRef} props={props} />
                ) : null}
              </div>
              {steps.map((step, index) => (
                <Step
                  key={step.name ? step.name : `step-${index}`}
                  step={step}
                  index={index}
                  isMultiStep={isMultiStep}
                  currentStep={currentStep}
                  config={config}
                  recaptchaRef={recaptchaRef}
                  props={props}
                />
              ))}
              {isMultiStep ? (
                <div>
                  {config.policy ? (
                    <div className="policy" dangerouslySetInnerHTML={{ __html: config.policy }} />
                  ) : null}
                  <div className="button-group">
                    {currentStep > 0 && (
                      <button type="button" className='back' onClick={handlePrevStep} dangerouslySetInnerHTML={{ __html: isMultiStep.previous }} />
                    )}
                    {config.multistep.button_group_text ? (<div className='button-group-text' dangerouslySetInnerHTML={{ __html: config.multistep.button_group_text }}></div>) : null}
                    {currentStep < steps.length - 1 && (
                      <button type="button" className='next' onClick={() => handleNextStep(props, steps[currentStep].fields)} dangerouslySetInnerHTML={{ __html: isMultiStep.next }} />
                    )}
                    {currentStep === steps.length - 1 && (
                      <button className={classNames('button primary submit', { loader: props.isSubmitting })} type="submit" disabled={disabled || props.isSubmitting} dangerouslySetInnerHTML={{ __html: config.submit }} />
                    )}
                  </div>
                </div>
              ) : (
                <div className='fefform-extras'>
                  {config.policy ? (
                    <div className="policy" dangerouslySetInnerHTML={{ __html: config.policy }} />
                  ) : null}
                  {recaptchaVersion === 'v3' ? (
                    <div className='captcha-policy'>This site is protected by reCAPTCHA and the Google <a href="https://www.google.com/intl/en/policies/privacy/" target="_blank" rel="noreferrer">Privacy Policy</a> and <a href="https://www.google.com/intl/en/policies/terms/" target="_blank" rel="noreferrer">Terms of Service</a> apply.</div>
                  ) : null}
                  {config.submit ? (
                    <div className="button-group">
                      <button onClick={() => {
                        props.submitForm()
                      }} className={classNames('button primary submit', { loader: props.isSubmitting })} type="button" disabled={disabled || props.isSubmitting} dangerouslySetInnerHTML={{ __html: config.submit }} />
                    </div>
                  ) : null}
                </div>
              )}
              <Modal
                isOpen={!!props.status}
                contentLabel={props.status?.title}
                className="FormErrors__Content"
                portalClassName="FormErrors"
                overlayClassName="FormErrors__Overlay"
              >
                <h3 className="pd_modal_title">{props.status?.title}</h3>
                <div className="pd_modal_content">
                  {props.status?.text}
                </div>
                <div className="pd_modal_footer">
                  <button className="button primary submit" onClick={() => props.setStatus('')}>Close</button>
                </div>
              </Modal>
            </CustomForm>
          )
        }}
      </Formik>
    </div>
  )
}

export default FrontendForm
