import classNames from 'classnames'
import { Field, ErrorMessage } from 'formik'
import React, { useEffect, useRef } from 'react'
import slugify from 'slugify'

import Check from '../Check/Check'
import { CustomErrorMessage } from '../renderField'


export default function CheckboxInput({ config, field }) {
  const { name, options, label, onRender, ...rest } = field

  const checks = options ? options : [ { label: label } ]
  const el = useRef()

  useEffect(() => {
    if (onRender) {
      onRender(el.current)
    }
  })

  return (
    <div ref={el} className={classNames('feffield', field.name, field.classes, { 'input-group-prefix': !!field.prefix, 'input-group-suffix': !!field.suffix })} key={`${config.name}-${field.name}`}>
      <div className={classNames('input-group', `group-${name}`)}>
        {options?.length && label ? (<label dangerouslySetInnerHTML={{ __html: label }} />) : null}
        {field.prefix && <div className="input-group-addon" dangerouslySetInnerHTML={{ __html: field.prefix }} />}
        {checks.map(check => (
          <Field key={`${config.selector.replace('#', '')}__${name}${options?.length ? `__${slugify(check.value.toString(), { lower: true })}` : ''}`} component={Check} id={`${config.selector.replace('#', '')}__${name}${options?.length ? `__${slugify(check.value.toString(), { lower: true })}` : ''}`} name={name} type={rest.type || 'checkbox'} {...rest} {...check} />
        ))}
        {field.suffix && <div className="input-group-addon" dangerouslySetInnerHTML={{ __html: field.suffix }} />}
      </div>
      <ErrorMessage name={field.name}>{msg => <CustomErrorMessage msg={msg} name={field.name} id={`${config.selector.replace('#', '')}__${name}${options?.length ? `__${slugify(checks[0].value.toString(), { lower: true })}` : ''}`} />}</ErrorMessage>
    </div>
  )
}
